/* Core styles */
.btn {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    user-select: none;
    padding: 8px 12px;
    min-height: 30px;
    min-width: 50px;
    background: #001122;
    color: white;
    border: 2px solid #113344;
    border-radius: 8px;
}
.btn:focus {
}
.btn:active {
    border-top-width: 1px;
    transform: translateY(1px);
}
.btn:hover {
    cursor: pointer;
}
.btn:disabled {
    cursor: not-allowed;
    color:rgb(170, 170, 170);
}
.btn:disabled:active {
    border-top-width: 2px;
    transform: none;
}

.btn .icon {
    width: 14px;
    height: 14px;
    font-size: 14px;
    margin-right: 6px;
    position: relative;
    top: 2px;
}

/* Styles by button type */
.btn.btn-primary {
    color: white;
    background: #00AFFF;
}
.btn.btn-primary:disabled {
    background: rgb(170, 170, 170);
}

.btn.btn-secondary {
    color: white;
    background: #113344;
}
.btn.btn-secondary:disabled {
    color:rgb(170, 170, 170);
}

.btn.btn-tertiary {
    background: none;
    border-radius: 0;
    border-bottom-color: white;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
}
.btn.btn-tertiary:disabled {
    color:rgb(170, 170, 170);
    border-bottom-color: rgb(170, 170, 170);
}

.btn.btn-icon {
    background: none;
    border: none;
    padding: 4px;
    min-width: unset;
}
.btn.btn-icon .icon {
    margin: 0;
    width: 22px;
    height: 22px;
    font-size: 22px;
    color:#00AFFF;
}
.btn.btn-icon:disabled, .btn.btn-icon:disabled .icon {
    color: rgb(170, 170, 170);
}