.app-section {
    position: relative;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 12px;
    border: 2px solid #113344;
    border-radius: 8px;
}

.app-section-header {
    font-size: 20px;
    margin: 0 0 16px 0;
}