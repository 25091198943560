.nav-title {
    display: flex;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
    padding: 8px 0;
    text-decoration: none;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}